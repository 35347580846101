import React, { useState } from 'react'
import dynamic from 'next/dynamic'

const Bookingstatus = dynamic(() => import('./BookingStatus'))
const Busbooking = dynamic(() => import('./BusBooking'))
const BookSeat = dynamic(() => import('./BookSeat'))

const bookingLinks = [
  { id: 1, name: 'Book a seat', link: 'seat' },
  { id: 2, name: 'Book a bus', link: 'bus' },
  { id: 3, name: 'Booking status', link: 'status' }
]

export default function BookingPage({ getTerminals }) {
  const [activeLink, setActiveLink] = useState('seat')
  const [loading, setLoading] = useState(false)

  return (
    <section className='border-t border-solid border-primary py-8 bg-transparent px-4 md:px-20 lg:px-0 md:mt-20'>
      <ul className='flex items-center justify-between lg:flex-row lg:py-4 lg:w-3/5 mx-auto py-6 md:w-4/5'>
        {bookingLinks.map((link) => (
          <li
            role='button'
            key={link.id}
            className={
              (activeLink === link.link
                ? ' text-priColor'
                : 'text-white hover:text-priColor') +
              ' flex items-center font-regular uppercase transition duration-300 ease-in-out text-sm md:text-base'
            }
            onClick={() => {
              setLoading(true)
              setActiveLink(link.link)
            }}
          >
            {link.name}
          </li>
        ))}
      </ul>

      {activeLink === 'seat' && (
        <BookSeat chnageLoader={setLoading} loader={loading} getTerminals={getTerminals}/>
      )}
      {activeLink === 'bus' && (
        <Busbooking chnageLoader={setLoading} loader={loading} getTerminals={getTerminals}/>
      )}
      {activeLink === 'status' && (
        <Bookingstatus chnageLoader={setLoading} loader={loading} getTerminals={getTerminals}/>
      )}
    </section>
  )
}
